
import Vue from "vue";
import { permissions } from "@/mixins";
import { Actions } from "@/store/models";
import { companies, devices, ICompany, IDevice, IListInfo } from "@/api";
import { getDate } from "@/utils";

export default Vue.extend({
  name: "GssiAdminAccounts",
  mixins: [permissions],
  data() {
    return {
      searchQuery: "",
      headers: [
        { text: "DEVICE TYPE", value: "device_type.name" },
        { text: "COMPANY", value: "company.name" },
        { text: "OWNER ID", value: "created_by" },
        { text: "SERIAL #", value: "device_serial_number" },
        { text: "UPDATED STATUS", value: "software_last_updated" },
        { text: "LAST ACTIVITY", value: "" },
      ],
      items: [] as IDevice[],
      listInfo: {} as IListInfo,
    };
  },

  computed: {
    filteredItems: function (): IDevice[] {
      return this.items.filter((item) => {
        return (
          item.device_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.device_serial_number
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
            const start_index = this.listInfo.start_index
        ? this.listInfo.start_index + this.listInfo.page_limit!
        : 1;
      try {
        const response = await devices.getAll({ start_index });
        response.data.forEach((item) => {
          item.software_last_updated = getDate(item.created_datetime);
          item.updated_datetime = getDate(item.updated_datetime!);
          this.items.push(item)
        });
        this.listInfo = response.list_info!;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
